<template>
  <v-card>
    <v-card-title class="justify-space-between mb-0 py-8">
      <span class="text-h5"><strong>Asst Manager HCRP</strong></span>
    </v-card-title>
    <v-row class="ml-2 mr-2 mb-3">
      <v-col cols="12" md="3">
        <v-text-field label="Name" v-model="filter.name" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0">
        <v-text-field label="Email" v-model="filter.email" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0">
        <v-text-field label="Phone No." v-model="filter.phone_number" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="pl-0 text-right">
        <v-btn color="primary" class="mr-2" @click="getTechniciansSearch()"> Search</v-btn>
        <v-btn color="primary"  @click="getTechnicians()"> Reset</v-btn>
      </v-col>
      <!-- <v-col cols="12" md="6">
      
      </v-col> -->
    </v-row>

    <v-data-table :headers="headers" :items="TechniciansList" :items-per-page="10"  fixed-header
      height="400" class="table-rounded">
      <template #[`item.operate`]="{ item }">
        <v-btn color="primary" class="mr-3" @click="editTechnicianList(item)"> Edit </v-btn>
        <EditTechnicianList :visible="editTechnician" :item="editItem" @close="editTechnician = false" />
        <v-btn color="error" text @click="deleteTechnicianList(item)"> Delete </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="DeleteModal" max-width="550">
      <v-card>
        <v-card-title class="text-h5"> Delete confirmation </v-card-title>
        <v-card-text> Are you sure you want to delete ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="DeleteModal = false"> Cancel </v-btn>
          <v-btn color="primary" @click="deleteTechnician(thisItemId)"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="addUserModal" max-width="1000">
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-h5"><strong> Add System User</strong></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="FirstName*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="LastName*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Email*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Phone No.*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Address*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete label="User Role" required :items="roleList"></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete label="Associate with" required :items="roleList"></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <small class="ml-3">*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="addUserModal = false"> Cancel </v-btn>
          <v-btn color="primary" @click="addUserModal = false"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import HCRPManagerservice from '@/service/AllMaster.service'
import EditTechnicianList from './EditHcrpmanager.vue'
const baseUrl = "https://api.stage.ebsr.in/users"
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
import axios from 'axios'
export default {
  components: {
    EditTechnicianList,
  },
  data() {
    return {
      listLoading: false,
      DeleteModal:false,
      totallist: 0,
      editItem:'',
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      editTechnician: false,
      thisItemId:'',
      headers: [
        { text: 'USER ID', value: 'id', align: ' d-none' },
        { text: 'NAME', value: 'name', width: '150px' },
        { text: 'EMAIL', value: 'email', width: '150px' },
        { text: 'PHONE NO.', value: 'phone_number', width: '150px' },
        { text: 'DISTRICT', value: 'district', width: '150px' },
        { text: 'DESIGNATION', value: 'designation', width: '200px' },
        { text: 'HOSPITAL', value: 'hospital_name', width: '150px' },
        { text: 'OPERATE', value: 'operate', width: '250px' },
      ],
      addUserModal: false,
      TechniciansList: [],
      filter: {
        phone_number: '',
        email: '',
        name: '',
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.getTechnicians()
      },
      deep: true,
    },
  },

  async mounted() {
    this.getTechnicians()
  },
  methods: {
    editTechnicianList(e) {
      this.editTechnician = true
      this.editItem = e
    },

     deleteTechnicianList(e) {
			this.DeleteModal = true
			this.thisItemId = e.id
			// console.log("items", e)
		},

    async getTechnicians() {
      this.listLoading = true
      const service = new HCRPManagerservice()
      let response = await service.HCRPManager()

      if (response) {
        this.TechniciansList = response.data
        this.totallist = this.TechniciansList.length
        this.filter.name = ''
        this.filter.email = ''
        this.filter.phone_number = ''
      } else {
        this.TechniciansList = []
        this.totallist = 0
      }
      this.listLoading = false
    },

    async getTechniciansSearch() {
      this.listLoading = true
      if(this.filter.phone_number || this.filter.email || this.filter.name){
      const data = {
        phone_number: this.filter.phone_number,
        name: this.filter.name,
        email: this.filter.email,
      }
      try {
        const response = await axios.post(`${baseUrl}/search`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response) {
          this.TechniciansList = response.data.data
          this.totallist = this.TechniciansList.length
        } else {
          this.TechniciansList = []
          this.totallist = 0
        }
      } catch (e) {
        console.log(e)
      }
    }else{
        
        this.snackbarText = "Kindly Enter The Keyword"
        this.snackbar = true
        return
      }
      this.listLoading = false
    },

    async deleteTechnician(item) {
      const data = {
        id: item,
      }
      try {
        const response = await axios.post(`${baseUrl}/technician/delete`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response.data.status == 200) {
          this.snackbarText = response.data.message
          this.getTechnicians()
          this.DeleteModal=false
        } else {
          this.snackbarText = response.data.message
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
